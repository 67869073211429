import React, { FC } from "react";

import { graphql } from "gatsby";
import BlogsMain from "../components/BlogUiComponents/BlogsMain/BlogsMain";
import Layout from "../components/Layout/Layout";
import { UseTagsFilter } from "../components/hooks/UseTagsFilter";

type Props = {
  pageContext: any;
  data: any;
  location: any;
};

const Tags: FC<Props> = ({ pageContext, data }) => {
  const { currentPage, techBlogsPaginationNumber, blogs, tag } = pageContext;

  let categoryItem = data.allSanityTag.nodes[0].title;

  const selectedTags = UseTagsFilter("tech-blog", blogs, data.allTags.nodes);

  let filteredArray: any = [];
  const checkPath = (data: any) => {
    data.allSanityBlog.edges.forEach((x: any) => {
      x.node.tags.forEach((y: any) => {
        if (y.title === categoryItem) {
          filteredArray.push(x);
        }
      });
    });
  };

  checkPath(data);

  return (
    <Layout location={"/blog"}>
      <>
        <BlogsMain
          basePathForUrl="/tech-blog"
          basePathForPaginations={`/tech-blog/${tag}`}
          blogName={"/tech-blog"}
          data={filteredArray}
          tags={selectedTags}
          numberPages={techBlogsPaginationNumber}
          currentPage={currentPage}
        />
      </>
    </Layout>
  );
};

export default Tags;

export const query = graphql`
  query ($tag: String, $skip: Int!, $limit: Int!) {
    allSanityBlog(
      limit: $limit
      skip: $skip
      filter: {
        blogType: { elemMatch: { title: { eq: "tech-blog" } } }
        tags: { elemMatch: { title: { eq: $tag } } }
      }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          blogType {
            title
          }
          _rawBody
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          publishedAt
          body {
            children {
              text
            }
          }
          tags {
            title
            _id
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          alt
        }
      }
      pageInfo {
        totalCount
      }
    }
    allTags: allSanityTag {
      nodes {
        title
        _id
      }
    }
    allSanityTag(filter: { title: { eq: $tag } }) {
      nodes {
        title
        _id
      }
    }
  }
`;
